import React, { Component } from 'react';
import { connect } from 'react-redux';
//import HousesView from './views/HousesView';
//import HouseDetailsView from './views/HouseDetailsView';
import SideNavigation from './views/SideNavigation';
import LoadingCover from './views/LoadingCover';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import HamburgerButton from './views/HamburgerButton';
//import MainSearch from './views/MainSearch';
//import ProvinceVillageSearch from './views/ProvinceVillageSearch';
//import ByDistanceSearch from './views/ByDistanceSearch';
import okLogo from './images/ok-logo.svg';
import './App.css';
import {baseName} from './helpers/myHelper';
//import ContactPage from './views/ContactPage';
//import HomeView from './views/HomeView';
import { translations} from "./mats/translations";
import { Suspense, lazy } from 'react';
//import SavedProperties from './views/SavedProperties';
//import SavedSearched from './views/SavedSearched';
import LoginPopup from './views/LoginPopup';
import MessagePopup from './views/MessagePopup';
import PrivacyPolicy from './views/PrivacyPolicy';
import anime from "animejs";

import { transliterate } from './helpers/myHelper';
import { Link } from 'react-router-dom';


const HousesView = lazy(() => import('./views/HousesView'));
const HouseDetailsView = lazy(() => import('./views/HouseDetailsView'));
const HomeView = lazy(() => import('./views/HomeView'));
const ContactPage = lazy(() => import('./views/ContactPage'));
const MainSearch = lazy(() => import('./views/MainSearch'));
const ProvinceVillageSearch = lazy(() => import('./views/ProvinceVillageSearch'));
const ByDistanceSearch = lazy(() => import('./views/ByDistanceSearch'));
const SavedProperties = lazy(() => import('./views/SavedProperties'));
const UserPage = lazy(() => import('./views/UserPage'));
const SavedSearched = lazy(() => import('./views/SavedSearched'));

class App extends Component {

  constructor(){
    super();
    this.state = {
      animated: false,
      allResults:0,
      upto5000:0,
      upto10000:0,
      upto30000:0
    }    
  }

  componentDidMount(){

      let theURL = 'https://m.okbulgaria.com/serve/get_data_heart.php';
      fetch(theURL)
      .then(response => response.json())
      .then(data => {          
        console.log(data);
        this.setState({allResults: data.all, upto5000: data.upto5000, upto10000: data.upto10000, upto30000: data.upto30000});
        var basicTimeline = anime.timeline();
        basicTimeline        
          .add({
            targets: '.logo-part_0',
            opacity: [1,1],
            scaleY: [0,1],
            scaleX: [10,1],
            duration: 600,
            easing: "easeInOutSine",
            delay: anime.stagger(40)
          }, 300)
          .add({
            targets: '.logo-part_1',
            opacity: [1,1],
            scaleY: [10,1],
            scaleX: [0,1],
            duration: 600,
            easing: "easeInOutSine",
            delay: anime.stagger(40)
          }, 300)      
          .add({
            targets: '.logo-part_2',
            opacity: [1,1],
            scaleY: [0,1],
            scaleX: [0,1],
            duration: 600,
            easing: "easeInOutSine",
            delay: anime.stagger(40)
          }, 100) 
          .add({
            targets: '#all_results',
            opacity: 1,
            translateY: ["-100px", "0px"],
            duration: 300,
            easing: "easeInOutSine",
          }, 1300)                
          
          .add({
            targets: "#okbg-hs-anim-holder",
            opacity: 0,
            duration: 250,
            easing: "linear",
            delay: 2200,
            complete: () => {this.setState({ animated : true})}
          })        
        

       })
      .catch(error => {
          console.log(error);
      })      






  }

    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }        


  render() {
    let RouteObjects = [];
    Object.keys(translations.url_properties).forEach(function(key_id){
      let urlHousesView0 = "/"+translations.url_properties[key_id]+"/:typeLabel/:locationLabel/:dateAddedLabel/:sortByLabel/min-:minPrice-max-:maxPrice/t_:pTypeID-l_:pLocationID-d_:dateAdded-sb_:sortBy-f_:hasFurn-sv_:hasSeaView-ks_:kmSea-ml_:minLand-";
      let urlHousesView1 = "/"+translations.url_properties[key_id]+"/:provinceLabel/:placeLabel/:sortByLabel/pr_:provinceID-pl_:placeID-sb_:sortBy-";
      let urlHousesView2 = "/"+translations.url_properties[key_id]+"/:provinceLabel/:sortByLabel/pr_:provinceID-sb_:sortBy-";
      let urlHousesView3 = "/"+translations.url_properties[key_id]+"/:dateAddedLabel/:sortByLabel/d_:dateAdded-sb_:sortBy-";
      let urlHousesView4 = "/"+translations.url_properties[key_id]+"/:distFromCity/:sortByLabel/min-:minPrice-max-:maxPrice/km_:cityDist-c_:cityID-sb_:sortBy-";
      let urlHousesView5 = "/"+translations.url_properties[key_id];
      let urlHousesView6 = "/"+translations.url_properties[key_id]+"/:typeLabel/:locationLabel/:dateAddedLabel/:sortByLabel/min-:minPrice-max-:maxPrice/t_:pTypeID-l_:pLocationID-d_:dateAdded-sb_:sortBy-";
      //let urlHousesView6 = "/"+translations.url_properties[key_id]+"/:typeLabel/:locationLabel/:dateAddedLabel/:sortByLabel/min-:minPrice-max-:maxPrice/t_:pTypeID-l_:pLocationID-d_:dateAdded-sb_:sortBy-";
      //let urlHousesView7 = "/"+translations.url_properties[key_id]+"/:typeLabel/:locationLabel/:dateAddedLabel/:sortByLabel/min-:minPrice-max-:maxPrice/t_:pTypeID-l_:pLocationID-d_:dateAdded-sb_:sortBy-f_:hasFurn-";


      let urlHouseView0 = "/"+translations.url_property[key_id]+"/:province/:place/:refID";
      let urlHouseView1 = "/"+translations.url_property[key_id]+"/:bulgaria/:refID";
      let urlHomeView = "/"+translations.url_home[key_id];
      let urlSavedProperties = "/"+translations.url_saved_properties[key_id];
      let urlSavedSearch = "/"+translations.url_saved_search[key_id];
      let urlUserPage = "/"+translations.user_page_url[key_id];
      
      RouteObjects.push(<Route key={key_id} path={urlHousesView0} component={HousesView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHousesView1} component={HousesView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHousesView2} component={HousesView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHousesView3} component={HousesView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHousesView4} component={HousesView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHousesView5} component={HousesView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHousesView6} component={HousesView} exact />);
      //RouteObjects.push(<Route key={key_id} path={urlHousesView7} component={HousesView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHouseView0} component={HouseDetailsView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHouseView1} component={HouseDetailsView} exact />);
      RouteObjects.push(<Route key={key_id} path={urlHomeView} component={HomeView} exact />);


      RouteObjects.push(<Route key={key_id} path={urlSavedProperties} component={SavedProperties} exact />);
      RouteObjects.push(<Route key={key_id} path={urlSavedSearch} component={SavedSearched} exact />);
      RouteObjects.push(<Route key={key_id} path={urlUserPage} component={UserPage} exact />);

    })

    var theImg = <img id="test" src="https://m.okbulgaria.com/images/logo_anim.png" alt="logo" />;
    var fallBack = <nav className="okbg-hs-navbar" style={{justifyContent: "center"}}><div><img style={{width: '100px', height: '24px'}} src={okLogo} alt="ok logo" /></div></nav>;
    if(!this.state.animated) fallBack = "";
    var parts = [];
    var cols = 5, rows = 5;
    var ox = 400/cols, oy = 400/rows;
    let part_group = 0, part_class = "";
    for(let j=0; j<cols;j++)
      for(let i=0; i<rows;i++){
        var key = "an_"+j+"_"+i;
        part_class = "logo-part_"+part_group;
        part_group++; if(part_group === 3) part_group = 0;
        parts.push(<div key={key} className={part_class} style={{opacity:'0', position: 'absolute', width: ox+'px', height: oy+'px', overflow: 'hidden', left: j*ox+'px', top: i*oy+'px'}}><div style={{position: 'absolute', left:-j*ox+'px', top: -i*oy+'px'}}>{theImg}</div></div>);
      }
    parts = this.shuffleArray(parts);
    let allResults = Number(this.state.allResults);
    let upto5000 = Number(this.state.upto5000);
    let upto10000 = Number(this.state.upto10000);
    let upto30000 = Number(this.state.upto30000);
    var animeHolder = <div id="okbg-hs-anim-holder">
      <div style={{width: '400px', height: '400px', position: 'relative'}}>
      {parts}
      </div>
      
      <div id="all_results" style={{margin: '0px auto', opacity: 0, width: '320px', fontSize: '28px', textAlign: 'center', fontWeight: 'bold', color: '#888'}}><p style={{color:'red', fontSize:'36px'}}>{allResults.toLocaleString()}</p>{translations.head3[this.props.lang]}</div>
    </div>
    if(this.state.animated) animeHolder = "";


//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------
//5000, 10000, 30000
    const {lang} = this.props;
    let homeLabel = translations.upto_5000[lang];
    let labels = []; let values = []; let textValue = ""; let extURL = "", theURL = "";
    //pTypeID
    textValue = translations.ptype_select[lang].options[0].replace(" - ", " ");
    extURL = "t_0-"; textValue = transliterate(textValue);
    labels.push(textValue); values.push(extURL);            
    //pLocationID
    textValue = translations.plocation_select[lang].options[0].replace(" - ", " ");
    extURL = "l_0-"; textValue = transliterate(textValue);
    labels.push(textValue); values.push(extURL);
    //dateAdded
    textValue = translations.date_added_select[lang].options[0].replace(" - ", " ");
    extURL = "d_0-"; textValue = transliterate(textValue);
    labels.push(textValue); values.push(extURL);            
    //sortBy
    textValue = translations.sort_by_select[lang].options[3].replace(" - ", " ");
    extURL = "sb_3-"; textValue = transliterate(textValue); 
    labels.push(textValue); values.push(extURL);            
    //min-max
    labels.push("min-0-max-5000");
    //funriture
    extURL = "f_0-";values.push(extURL);
    //sea view
    extURL = "sv_0-";values.push(extURL);
    //km sea
    extURL = "ks_0-";values.push(extURL);
    //low land
    extURL = "ml_0-";values.push(extURL);
    theURL = ""; extURL = "";

    for(let i=0;i<labels.length;i++){
        theURL = theURL + labels[i] + "/";
    }
    for(let i=0;i<values.length;i++){
        extURL = extURL + values[i];
    }

    let homeLabel10000;
    let theURL10000 = theURL.replace("5000", "10000");
    let homeLabel30000;
    let theURL30000 = theURL.replace("5000", "30000");

    if(lang === "de" || lang === "fr"){
      homeLabel10000 = homeLabel.replace("5000", "10,000")+'('+upto10000+')';
      homeLabel30000 = homeLabel.replace("5000", "30,000")+'('+upto30000+')';
      homeLabel = homeLabel.replace("5000", "5,000")+'('+upto5000+')';
    }
    else{
      homeLabel10000 = homeLabel.replace("5000", "10.000")+'('+upto10000+')';
      homeLabel30000 = homeLabel.replace("5000", "30.000")+'('+upto30000+')';
      homeLabel = homeLabel.replace("5000", "5.000")+'('+upto5000+')';
    }
    

    homeLabel = <Link className="okbg-hs-desktop-nav" to={"/"+translations.url_properties[lang]+"/"+theURL+extURL+"/?lang="+lang}>{homeLabel}</Link>;
    homeLabel10000 = <Link className="okbg-hs-desktop-nav" to={"/"+translations.url_properties[lang]+"/"+theURL10000+extURL+"/?lang="+lang}>{homeLabel10000}</Link>;
    homeLabel30000 = <Link className="okbg-hs-desktop-nav" to={"/"+translations.url_properties[lang]+"/"+theURL30000+extURL+"/?lang="+lang}>{homeLabel30000}</Link>;
//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------


//<Link className="okbg-hs-side-nav-link" to={"/"+translations.url_home[lang]+"?lang="+lang}>{translations.home_nav[lang]}</Link>

    return (
      <BrowserRouter basename={baseName}>
        <Suspense fallback={fallBack}>        
        <div className="App">
          {animeHolder}
          <div style={{height: '40px'}}>&nbsp;</div>
          <nav className="okbg-hs-navbar">


            <Link style={{position: "absolute", zIndex: "1", width: "90%", height:"40px", display: "block"}} to={"/"+translations.url_home[lang]+"?lang="+lang}></Link>
            <div style={{position: "relative", zIndex: "2", display: "flex", alignItems: "center"}}>
              <div><Link to={"/"+translations.url_home[lang]+"?lang="+lang}><img style={{width: '100px', height: '24px', marginRight: "10px"}} src={okLogo} alt="ok logo" /></Link></div>
              <div className="okbg-hs-desktop-nav-holder">{homeLabel}</div>
              <div className="okbg-hs-desktop-nav-holder">{homeLabel10000}</div>
              <div className="okbg-hs-desktop-nav-holder">{homeLabel30000}</div>
            </div>
            <HamburgerButton lang={this.props.lang} user={this.props.userName} />

            
          </nav>
          <SideNavigation />
          <Switch>
          {RouteObjects}
          {/*<Route path="/properties/:typeLabel/:locationLabel/:dateAddedLabel/:sortByLabel/min-:minPrice-max-:maxPrice/t_:pTypeID-l_:pLocationID-d_:dateAdded-sb_:sortBy-" component={HousesView} exact />*/}
          {/*<Route path="/properties/:provinceLabel/:placeLabel/:sortByLabel/pr_:provinceID-pl_:placeID-sb_:sortBy-" component={HousesView} exact />*/}
          {/*<Route path="/properties/:provinceLabel/:sortByLabel/pr_:provinceID-sb_:sortBy-" component={HousesView} exact />*/}
          {/*<Route path="/properties/:dateAddedLabel/:sortByLabel/d_:dateAdded-sb_:sortBy-" component={HousesView} exact />*/}
          {/*<Route path="/properties/:distFromCity/:sortByLabel/min-:minPrice-max-:maxPrice/km_:cityDist-c_:cityID-sb_:sortBy-" component={HousesView} exact />*/}
          {/*<Route path="/properties" component={HousesView} />*/}
          {/*<Route path="/property/:province/:place/:refID" component={HouseDetailsView} exact />*/}
          {/*<Route path="/property/:refID" component={HouseDetailsView} />*/}
          {/*<Route path="/home" component={HomeView} />*/}
          <Route path="/main-search" component={MainSearch} />
          <Route path="/province-village-search" component={ProvinceVillageSearch} />
          <Route path="/by-distance-search" component={ByDistanceSearch} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />

          {/* tuk prihwashtam vlizane po domain i prehwyrljam w landing s default ezik*/}
          {/*<Redirect from="/" to={{ pathname: "/properties/last-10-days/sort-by-price/d_240-sb_3-/", search: "?lang=en"}}/>*/}
          {/*<Redirect from="/" to={{ pathname: "/properties/all-types/all-locations/last-10-days/price-low-to-high/min-0-max-500000/t_0-l_0-d_240-sb_3-/", search: "?lang=en"}}/>*/}

          {<Redirect from="/" to={{ pathname: "/home", search: "?lang=en"}}/>}

          </Switch>
          <LoadingCover />
          <LoginPopup />
          <MessagePopup />

        </div>
        </Suspense>
      </BrowserRouter>
    );
  }
}


//export default App;


const mapStateToProps = (state, ownProps) => {
  return {
       lang: state.mainReducer.lang,
       userName: state.mainReducer.userName,
  }
}
export default connect(mapStateToProps)(App);
