import {RESOURCES_LOADING, HOUSES_LOADED, ERROR_LOADING_RESOURCES, HOUSE_LOADED, CHANGE_LANG_SELECTOR_STATUS, CHANGE_SIDE_NAV_STATUS, CHANGE_LANGUAGE, REDIRECT_TO, FORM_SELECTED_VALUES, SET_PROVINCE, SET_PLACE, SET_CITY, SET_START, INIT_START, HOME_RESOURCE_LOADING, HOME_HOUSES_LOADED, SET_HOME_START, SET_HOME_SORT, SET_USER_EMAIL, UPDATE_SUBSCRIBE_PROPERTY, UPDATE_LOGIN_POPUP, UPDATE_SUBSCRIBE_PROPERTY_DETAILS, UPDATE_BACK_URL, RESOURCES_LOADED, UPDATE_RELOAD_SAVED, UPDATE_SUB_MD5, UPDATE_MESSAGE_POPUP, UPDATE_HOUSES_OVER, COUNTS_LOADED, UPDATE_ZOOM_ID} from "./actions";
import logo from '../images/ok_bulgaria_logo.png';
import { getParameterValue, SupportedLanguages } from '../helpers/myHelper';

let empty_houses = [];
for(let i=0;i<12;i++){
    empty_houses.push({"ref_id":"Loading "+i+"...","pic": logo ,"plocation":"...","p_title":"...","pprice":"0", "place_name":"0", "province_name":"0"});
}

let queryLang =  getParameterValue(window.location.search, "lang");
if(!queryLang) {
    queryLang = SupportedLanguages.ENGLISH;
}
//console.log("reducer", queryLang);


function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
let saved_email = getCookie("okbulgaria_email");
if(!saved_email){
    saved_email = "N/A";
}
let saved_user = getCookie("okbulgaria_user");
if(!saved_user){
    saved_user = "N/A";
}



const INITIAL_STATE = {
    loading: false,
    loaded_home: [false, false, false, false, false],
    loaded: false,
    error: null,
    sub_md5: "",
    sub_sl_id: 0,
    houses : [...empty_houses],
    sub_md5_home : ["","","","",""],
    sub_sl_id_home: [0,0,0,0,0],
    houses_home: [[...empty_houses], [...empty_houses], [...empty_houses], [...empty_houses], [...empty_houses]],
    houses_over: [0,0,0,0,0,0,0,0,0,0,0,0],
    lang : queryLang,
    loadedLang: '',
    theRate: '1',
    moneySign: '',
    house: {pprice: 0, ref_id: ''},
    langOpened: false,
    sideNavOpened: false,
    redirectToNewPage: '',
    searchType : '0',
    searchLocation : '0',
    searchInputMin : 0,
    searchInputMax : 500000,
    searchDateAdded : "0",
    searchProvinceID : "0",
    searchProvinceLabel: "",
    searchPlaceID : "0",
    searchPlaceLabel : "",
    searchDistance : '10',
    searchCityID : "0",
    searchCityLabel : "",
    searchSortBy : "3",
    searchSortBy_home : ["3", "3", "3", "3", "3"],
    numResults : "0",
    allResults: "",
    start: "0",
    numResults_home: ["0", "0", "0", "0", "0"],
    start_home: ["0", "0", "0", "0", "0"],
    limitCount: "12",
    userEmail: saved_email,
    userName: saved_user,
    showLoginPopup: "0",
    backURL: "",
    reloadSaved: false,
    messagePopup: "",
    searchInputFurnished: false,
    searchSeaView: false,
    searchInputAreaMin: 0,
    searchInputLandMin: 0,
    searchInputKmSea: 0,
    count_by_province: [],
    zoom_id: 0
}

export const mainReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_SUBSCRIBE_PROPERTY_DETAILS:{
            let newHouse = Object.assign({}, state.house, {
                saved: action.payload.data.saved
            })
            return {
                ...state,
                house: newHouse
            }
        }
        case UPDATE_HOUSES_OVER:{
            const houses_over = state.houses_over.map((house_over, ind) => {
                if(ind === action.payload.index){
                    return action.payload.value;
                }
                else return house_over;
            })
            return {
                ...state,
                houses_over: houses_over,
            }
        }
        case UPDATE_SUBSCRIBE_PROPERTY:{
            const houses_home = state.houses_home.map((item, j) => {
                const list = item.map((house, i) => {
                    if(house.ref_id === action.payload.data.sub_ref_id){
                        house.saved = action.payload.data.saved;
                        return house;
                    }
                    else return house;
                })
                return list
            })
            const houses = state.houses.map((house, i) => {
                if(house.ref_id === action.payload.data.sub_ref_id){
                    house.saved = action.payload.data.saved;
                    return house;
                }
                else return house;
            })
            return {
                ...state,
                houses_home: houses_home,
                houses: houses,
            }
        }
        case HOME_RESOURCE_LOADING:{
            const loaded_home = state.loaded_home.map((item, j) => {
                if (j === action.payload.housesIndex) {
                  return false;
                } else {
                  return item;
                }
            });
            return {
                ...state, 
                loading: true, 
                loaded: false, 
                loaded_home: loaded_home,
                langOpened: false,
                sideNavOpened: false,
                redirectToNewPage: '',
                lang: action.payload.lang
            };
        }
        case RESOURCES_LOADED:{
            return {
                ...state, 
                loading: false,
                loaded: true
            }
        }
        case RESOURCES_LOADING:{
            return {
                ...state, 
                loading: true, 
                loaded: false, 
                langOpened: false,
                sideNavOpened: false,
                redirectToNewPage: '',
                lang: action.payload
            };
        }
        case ERROR_LOADING_RESOURCES:{
            return {
                ...state, 
                loading: false, 
                loaded: false, 
                error: action.payload
            };
        }
        case HOUSES_LOADED:{
            const oldLang = state.lang;
            return {
                ...state, 
                loading: false,
                loaded: true,
                houses: action.payload.result,
                theRate: action.payload.rates.the_rate,
                moneySign: action.payload.rates.money_sign,
                searchProvinceLabel: action.payload.provinceLabel,
                searchPlaceLabel: action.payload.placeLabel,
                searchCityLabel: action.payload.cityLabel,
                loadedLang: oldLang,
                numResults: action.payload.num,
                allResults: action.payload.all,
                sub_md5: action.payload.sub_md5,
                sub_sl_id:  action.payload.sub_sl_id,
            };
        }
        case HOME_HOUSES_LOADED:{
            const loaded_home = state.loaded_home.map((item, j) => {
                if (j === action.payload.housesIndex) {
                  return true;
                } else {
                  return item;
                }
            });
            const numResults_home = state.numResults_home.map((item, j) => {
                if (j === action.payload.housesIndex) {
                  return action.payload.data.num;
                } else {
                  return item;
                }
            });
            const houses_home = state.houses_home.map((item, j) => {
                if (j === action.payload.housesIndex) {
                  return action.payload.data.result;
                } else {
                  return item;
                }
            });
            let newLoaded = true;
            if( action.payload.housesIndex === 3) newLoaded = true;
            else{
                if(state.lang === "fr" || state.lang === "de") newLoaded = newLoaded && loaded_home[0] && loaded_home[1] && loaded_home[2] && loaded_home[4];
                else newLoaded = newLoaded && loaded_home[0] && loaded_home[1] && loaded_home[4];
            }
            let newLoading = !newLoaded;
            return {
                ...state,
                houses_home: houses_home,
                loaded_home: loaded_home,
                loading: newLoading,
                loaded: newLoaded,
                theRate: action.payload.data.rates.the_rate,
                moneySign: action.payload.data.rates.money_sign,
                numResults_home: numResults_home,
                allResults: action.payload.data.all
            };
        }
        case HOUSE_LOADED:{
            return {
                ...state, 
                loading: false,
                loaded: true,
                house: action.payload.result,
                theRate: action.payload.rates.the_rate,
                moneySign: action.payload.rates.money_sign,

            }
        }
        case COUNTS_LOADED:{
            return {
                ...state, 
                loading: false,
                loaded: true,
                count_by_province: action.payload.result,
            }
        }
        case FORM_SELECTED_VALUES: {
            //console.log(action.payload);
            return {
                ...state,
                searchType : action.payload.ptype_id,
                searchLocation : action.payload.plocation_ids,
                searchInputMin : action.payload.minPrice,
                searchInputMax : action.payload.maxPrice,
                searchDateAdded : action.payload.date_added,
                searchProvinceID : action.payload.pprovince_id,
                searchPlaceID : action.payload.pplace_id,
                searchDistance : action.payload.distance_city,
                searchCityID : action.payload.city_id,
                searchSortBy : action.payload.sort_by,
                searchInputFurnished : action.payload.s_furnished_check,
                searchSeaView : action.payload.s_sea_view_check,
                searchInputLandMin : action.payload.s_low_land,
                searchInputKmSea: action.payload.s_km_sea/1000
            }
        }
        case CHANGE_LANG_SELECTOR_STATUS:{
            return {
                ...state, 
                langOpened: action.payload,
            };
        }
        case CHANGE_SIDE_NAV_STATUS:{
            return {
                ...state, 
                sideNavOpened: action.payload,
            };
        }
        case CHANGE_LANGUAGE: {
            return {
                ...state,
                lang: action.payload,
                langOpened: false,
                sideNavOpened: false,
            }
        }
        case REDIRECT_TO: {
            return{
                ...state,
                redirectToNewPage: action.payload
            }
        }
        case SET_USER_EMAIL: {
            return{
                ...state,
                userEmail: action.payload.userEmail,
                userName: action.payload.userName,
            }
        }
        case SET_PROVINCE: {
            return{
                ...state,
                searchProvinceID: action.payload.value,
                searchProvinceLabel: action.payload.label               
            }
        }
        case SET_PLACE: {
            return{
                ...state,
                searchPlaceID: action.payload.value,              
                searchPlaceLabel: action.payload.label               
            }
        }
        case SET_CITY: {
            return{
                ...state,
                searchCityID: action.payload.value,              
                searchCityLabel: action.payload.label               
            }
        }
        case SET_START: {
            return{
                ...state,
                start: Number(state.start) + action.payload*Number(state.limitCount)
            }
        }
        case SET_HOME_START: {
            const start_home = state.start_home.map((item, j) => {
                if (j === action.payload.housesIndex) {
                  return Number(item) + action.payload.nextPrev*Number(state.limitCount);
                } else {
                  return item;
                }
            });

            return{
                ...state,
                start_home: start_home
            }
        }
        case INIT_START: {
            return{
                ...state,
                start: action.payload
            }
        }
        case UPDATE_LOGIN_POPUP: {
            return{
                ...state,
                showLoginPopup: action.payload
            }
        }
        case UPDATE_BACK_URL: {
            return{
                ...state,
                backURL: action.payload
            }
        }
        case UPDATE_SUB_MD5:{
            return{
                ...state,
                sub_md5: action.payload
            }
        }
        case UPDATE_RELOAD_SAVED: {
            return{
                ...state,
                reloadSaved: action.payload
            }
        }
        case UPDATE_MESSAGE_POPUP: {
            return{
                ...state,
                messagePopup: action.payload
            }
        }
        case UPDATE_ZOOM_ID: {
            return{
                ...state,
                zoom_id: action.payload
            }
        }
        case SET_HOME_SORT: {
            const searchSortBy_home = state.searchSortBy_home.map((item, j) => {
                if (j === action.payload.housesIndex) {
                  return action.payload.sortBy;
                } else {
                  return item;
                }
            });

            return{
                ...state,
                searchSortBy_home: searchSortBy_home
            }
        }
        default:
            return state;
    }
}