//polyfill for URLSearchParams
export function getParameterValue(searchString, key){    
    let params = new URLSearchParams(searchString);
    let value = params.get(key);
    if(value){
        //console.log("URLSearchParams");
        return value;
    }
    else {
        //console.log("polyfill URLSearchParams");
        key = key.replace(/[[]/, '[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
        let results = regex.exec(searchString);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}



export function transliterate(word){
    //http://translit.cc/
    var a = {"ы": "y", "ё": "yo", "э": "je", "ă":"a", "â":"a", "î":"i", "ș":"s", "ț":"t", "а":"a", "б":"b", "в":"v", "г":"g", "д":"d", "е":"e", "ж":"j", "з":"z", "и":"i", "й":"y", "к":"k", "л":"l", "м":"m", "н":"n", "о":"o", "п":"p", "р":"r", "с":"s", "т":"t", "у":"u", "ф":"f", "х":"h", "ц":"c", "ч":"ch", "ш":"sh", "щ":"sht", "ъ":"a", "ь":"y", "ю":"yu", "я":"ya", " ":"-", "[":"", "]":""};
    word = word.replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}\\]/gi, '');
    word = word.toLowerCase();
    word = word.replace(/ +/g," ");
    return word.split('').map(function (char) { 
        return a[char] || char; 
    }).join("");
}


export const SupportedLanguages = {
    ENGLISH: 'en',
    RUSSIAN: 'ru',
    DEUTCH: 'de',
    FRENCH: 'fr',
    POLISH: 'pl'
};

export const heartFills = ["rgba(0,0,0,0.5)", "rgba(255,0,0,0.1)", "red"];
export const heartStrokes = ["white", "red", "red"];


//export const baseName = "/_test/site-react";
//export const baseName = "/new/";
export const baseName = "/";