import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleSideNav } from '../redux/actions';
//import { translations} from "../mats/translations";
//import { transliterate } from '../helpers/myHelper';
//import { Link } from 'react-router-dom';
import NavLanguageSelector from './NavLanguageSelector';
import { Link } from 'react-router-dom';
import { translations} from "../mats/translations";


class HamburgerButton extends Component {

    openSideNav = (event) => {
        this.props.dispatch(toggleSideNav(true));
    }
    
    render() {
        /*
        const {lang} = this.props;
        let homeLabel = translations.home[1][lang];
        let labels = []; let values = []; let textValue = ""; let extURL = "", theURL = "";
        //pTypeID
        textValue = translations.ptype_select[lang].options[0].replace(" - ", " ");
        extURL = "t_0-"; textValue = transliterate(textValue);
        labels.push(textValue); values.push(extURL);            
        //pLocationID
        textValue = translations.plocation_select[lang].options[0].replace(" - ", " ");
        extURL = "l_0-"; textValue = transliterate(textValue);
        labels.push(textValue); values.push(extURL);
        //dateAdded
        textValue = translations.date_added_select[lang].options[0].replace(" - ", " ");
        extURL = "d_0-"; textValue = transliterate(textValue);
        labels.push(textValue); values.push(extURL);            
        //sortBy
        textValue = translations.sort_by_select[lang].options[3].replace(" - ", " ");
        extURL = "sb_3-"; textValue = transliterate(textValue); 
        labels.push(textValue); values.push(extURL);            
        //min-max
        labels.push("min-0-max-5000");
        //funriture
        extURL = "f_0-";values.push(extURL);
        //sea view
        extURL = "sv_0-";values.push(extURL);
        //km sea
        extURL = "ks_0-";values.push(extURL);
        //low land
        extURL = "ml_0-";values.push(extURL);
        theURL = ""; extURL = "";

        for(let i=0;i<labels.length;i++){
            theURL = theURL + labels[i] + "/";
        }
        for(let i=0;i<values.length;i++){
            extURL = extURL + values[i];
        }
        let homeLabel10000 = homeLabel.replace("5000", "10000");
        let theURL10000 = theURL.replace("5000", "10000");
        let homeLabel30000 = homeLabel.replace("5000", "30000");
        let theURL30000 = theURL.replace("5000", "30000");

        homeLabel = <Link className="okbg-hs-desktop-nav" to={"/"+translations.url_properties[lang]+"/"+theURL+extURL+"/?lang="+lang}>{homeLabel}</Link>;
        homeLabel10000 = <Link className="okbg-hs-desktop-nav" to={"/"+translations.url_properties[lang]+"/"+theURL10000+extURL+"/?lang="+lang}>{homeLabel10000}</Link>;
        homeLabel30000 = <Link className="okbg-hs-desktop-nav" to={"/"+translations.url_properties[lang]+"/"+theURL30000+extURL+"/?lang="+lang}>{homeLabel30000}</Link>;
        */


        const {lang, user} = this.props;
        let login_text = translations.login[lang];
        if(user !== "N/A") login_text = user;
        return (
        <div style={{display: "flex", alignItems: "center", zIndex: "100"}}>
            {/*<div className="okbg-hs-desktop-nav-holder">{homeLabel}</div>
            <div className="okbg-hs-desktop-nav-holder">{homeLabel10000}</div>
            <div className="okbg-hs-desktop-nav-holder">{homeLabel30000}</div>*/}
            <NavLanguageSelector /> 
            {
            <div><Link to={"/"+translations.user_page_url[lang]+"?lang="+lang} id="top-user-button" style={{backgroundImage: 'url("'+process.env.PUBLIC_URL+'/images/user.png")'}}>{login_text}</Link></div>         
            }
            <div>
                <span onClick={this.openSideNav} style={{cursor: 'pointer'}}>
                    <svg style={{display: 'block', width: '30px', height: '30px'}}>
                    <path d="M5,8 25,8" stroke="#fff" strokeWidth="2" />
                    <path d="M5,15 25,15" stroke="#fff" strokeWidth="2" />
                    <path d="M5,22 25,22" stroke="#fff" strokeWidth="2" />
                    </svg>	
                </span>        
            </div>
        </div>
        )
  }
}

export default connect()(HamburgerButton);
