import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setUserEmail, updateLoginPopup } from "../redux/actions";
//import FacebookLogin from "react-facebook-login";
import FormLogin from './FormLogin';
/*
shte trjabwa da naprawia now class kato formcontact s edno pole i submit button.
toi shte dispatch-va setUserEmail s vyvedenia email (kakto prawi responseFacebook)
*/

class LoginPopup extends Component {
/*
    responseFacebook = (response) => {
        this.props.dispatch(setUserEmail(response.email));
        this.props.dispatch(updateLoginPopup("0"));
    }
*/    
    componentWillUpdate = (data) => {
        console.warn(data);
    }

    closeLoginPopup = (event) => {
        this.props.dispatch(updateLoginPopup("0"));
    }    
    
    submit = (values) => {

        
        function setCookie(name,value,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }
        setCookie("okbulgaria_email", values.login_email, 30);
        setCookie("okbulgaria_user", values.login_user, 30);

        this.props.dispatch(setUserEmail(values.login_email, values.login_user))
        this.props.dispatch(updateLoginPopup("0"));
    }


    render() {
        return (
            <React.Fragment>
                <div id="login-popup" className={this.props.showLoginPopup === "1" ? "login-popup-showing" : ""}>
                    <span id="closeLoginPopup" className="okbg-hs-side-nav-link okbg-hs-btn-close" style={{border: '0px', right: '5px'}} onClick={this.closeLoginPopup}>&times;</span>

                    <div className="okbg-hs-panel" style={{padding: '10px'}}>
                        <FormLogin id="login_form" lang={this.props.lang} onSubmit={this.submit} ref="formLogin" />
                        {/*<hr></hr>
                        <FacebookLogin appId="2390219851217822" autoLoad={true} fields="email" onClick={this.componentClicked} callback={this.responseFacebook} />*/}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        showLoginPopup: state.mainReducer.showLoginPopup,
        lang: state.mainReducer.lang
    }
}

export default connect(mapStateToProps)(LoginPopup);