import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toggleSideNav, setUserEmail, updateLoginPopup } from '../redux/actions';
import { translations} from "../mats/translations";
//import { transliterate } from '../helpers/myHelper';


class SideNavigation extends Component {
  toggleTheme(e){
    let trans = () => {
      document.documentElement.classList.add('transition');
      window.setTimeout(() => {
        document.documentElement.classList.remove('transition');
      }, 1000);
    };
    if (e.target.checked) {
      trans();
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      trans();
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }

  closeSideNav = (event) => {
    this.props.dispatch(toggleSideNav(false));
  }

  logoutUser = (event) => {
    this.props.dispatch(setUserEmail("N/A", "N/A"));
    this.props.dispatch(updateLoginPopup("1"));
    function eraseCookie(name) {   
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    eraseCookie("okbulgaria_email");
    eraseCookie("okbulgaria_user");
  }

  render() {
    const { lang, userEmail } = this.props;
    let openedStyle = this.props.sideNavOpened ? { width: '250px' } : { width: '0px' };
    /*
    let distURL = transliterate(translations.ptype_select[lang].options[0].replace(" - ", " "))+"/";
    distURL = distURL + transliterate(translations.plocation_select[lang].options[0].replace(" - ", " "))+"/";
    distURL = distURL + transliterate(translations.date_added_select[lang].options["240"].replace(" - ", " "))+"/";
    distURL = distURL + transliterate(translations.sort_by_select[lang].options[0].replace(" - ", " "))+"/";
    distURL = distURL + "min-0-max-500000/";
    distURL = distURL + "t_0-l_0-d_240-sb_3-";
    */
    let displayLogoutStyle = 'inline-block';
    //let loggedEmail = ``;
    if(userEmail === "N/A") {
      displayLogoutStyle = 'none';
    }
    else{
      //loggedEmail = <div style={{position: "absolute", bottom: "5px", left: "10px", fontSize: "10px"}}>{userEmail}</div>
    }
    let list_link = ``;
    if(translations.list_property[lang] !=="N/A") list_link = <a className="okbg-hs-side-nav-link" href={'https://www.okbulgaria.bg/'+translations.list_property_url[lang]+'?lang='+lang} style={{textTransform: 'capitalize'}}>{translations.list_property[lang]}</a>
    return (
      <React.Fragment>
      {/*<h1>{translations.head1[lang]}</h1>*/}
      <div id="side-menu" className="okbg-hs-side-nav" style={openedStyle}>
        <span className="okbg-hs-side-nav-link okbg-hs-btn-close" style={{border: '0px'}} onClick={this.closeSideNav}>&times;</span>
        {/*<Link className="okbg-hs-side-nav-link" to={"/properties/"+distURL+"/?lang="+lang}>{translations.home_nav[lang]}</Link>*/}
        <Link className="okbg-hs-side-nav-link" to={"/"+translations.url_home[lang]+"?lang="+lang}>{translations.home_nav[lang]}</Link>
        <Link className="okbg-hs-side-nav-link okbg-hs-search-bgr" to={"/main-search?lang="+lang} onClick={this.closeSideNav}>{translations.main_search[lang]}</Link>
        <Link className="okbg-hs-side-nav-link okbg-hs-search-bgr" to={"/province-village-search?lang="+lang} onClick={this.closeSideNav}>{translations.search_province_village[lang]}</Link>
        <Link className="okbg-hs-side-nav-link okbg-hs-search-bgr" to={"/by-distance-search?lang="+lang} onClick={this.closeSideNav}>{translations.search_by_distance[lang]}</Link>
        <Link className="okbg-hs-side-nav-link" to={"/contact?lang="+lang} onClick={this.closeSideNav}>{translations.contactus[lang]}</Link>

        <Link className="okbg-hs-side-nav-link" to={"/"+translations.user_page_url[lang]+"?lang="+lang} onClick={this.closeSideNav}>{translations.user_page[lang]}</Link>

        {/*
        <Link className="okbg-hs-side-nav-link" to={"/"+translations.url_saved_properties[lang]+"?lang="+lang} onClick={this.closeSideNav}>{translations.saved_properties[lang]}</Link>
        <Link className="okbg-hs-side-nav-link" to={"/"+translations.url_saved_search[lang]+"?lang="+lang} onClick={this.closeSideNav}>{translations.saved_search[lang]}</Link>        
        */}

        <Link className="okbg-hs-side-nav-link" to={"/privacy-policy?lang="+lang} onClick={this.closeSideNav}>{translations.privacy_policy[lang]}</Link>
        <a className="okbg-hs-side-nav-link" href={translations.domains[lang]+'/index.php?full=yes'} style={{textTransform: 'capitalize'}}>{translations.view_desktop_version[lang]}</a>
        {list_link}

        <button className="okbg-hs-side-nav-link" onClick={this.logoutUser} style={{width: '80%', marginTop: '10px', marginLeft: '10px', display: displayLogoutStyle}}>{translations.logout[lang]}</button>
        {/*loggedEmail*/}
        {/*<Link className="okbg-hs-side-nav-link" to={"/"+translations.url_saved_search[lang]+"?lang="+lang} onClick={this.closeSideNav}>{translations.saved_search[lang]}</Link>*/}
        
        <div id="okbg-hs-toggle-holder">
          <input type="checkbox" onChange={this.toggleTheme} id="switch" /><label className="theme-toggle" htmlFor="switch">Toggle</label>
          <span style={{marginLeft: "10px"}}>{translations.theme[lang]}</span>
        </div>

      </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      sideNavOpened: state.mainReducer.sideNavOpened,
      userEmail: state.mainReducer.userEmail,
      lang: state.mainReducer.lang
    }
}

export default connect(mapStateToProps)(SideNavigation);