export const translations = {
    "page_of" : {
        "en" : "page %1% of %2%",
        "de" : "Seite %1% von %2%",
        "ru" : "страница %1% из %2%",
        "fr" : "page %1% de %2%",
        "pl" : "strona %1% z %2%"
    },
    "karta_help" : {
        "en" : "latin",
        "de" : "latin",
        "ru" : "cyr",
        "fr" : "latin",
        "pl" : "latin"
    },
    "karta_region" : {
        "latin" : {
            "BG-01" : "Blagoevgrad",
            "BG-02" : "Burgas",
            "BG-08" : "Dobrich",
            "BG-07" : "Gabrovo",
            "BG-26" : "Haskovo",
            "BG-09" : "Kardzhali",
            "BG-10" : "Kyustendil",
            "BG-11" : "Lovech",
            "BG-12" : "Montana",
            "BG-13" : "Pazardzhik",
            "BG-14" : "Pernik",
            "BG-15" : "Pleven",
            "BG-16" : "Plovdiv",
            "BG-17" : "Razgrad",
            "BG-18" : "Ruse",
            "BG-27" : "Shumen",
            "BG-19" : "Silistra",
            "BG-20" : "Sliven",
            "BG-21" : "Smolyan",
            "BG-23" : "Sofia",
            "BG-22" : "Sofia grad",
            "BG-24" : "Stara Zagora",
            "BG-25" : "Targovishte",
            "BG-03" : "Varna",
            "BG-04" : "Veliko Tarnovo",
            "BG-05" : "Vidin",
            "BG-06" : "Vratsa",
            "BG-28" : "Yambol"
        },
        "cyr" : {
            "BG-01" : "Благоевград",
            "BG-02" : "Бургас",
            "BG-08" : "Добрич",
            "BG-07" : "Габрово",
            "BG-26" : "Хасково",
            "BG-09" : "Кърджали",
            "BG-10" : "Кюстендил",
            "BG-11" : "Ловеч",
            "BG-12" : "Монтана",
            "BG-13" : "Пазарджик",
            "BG-14" : "Перник",
            "BG-15" : "Плевен",
            "BG-16" : "Пловдив",
            "BG-17" : "Разград",
            "BG-18" : "Русе",
            "BG-27" : "Шумен",
            "BG-19" : "Силистра",
            "BG-20" : "Сливен",
            "BG-21" : "Смолян",
            "BG-23" : "София",
            "BG-22" : "София град",
            "BG-24" : "Стара Загора",
            "BG-25" : "Търговище",
            "BG-03" : "Варна",
            "BG-04" : "Велико Търново",
            "BG-05" : "Видин",
            "BG-06" : "Враца",
            "BG-28" : "Ямбол"
        }
    },
    "regions_properties" : {
        "en" : "We have properties in the following regions:",
        "de" : "Wir haben Immobilien in folgenden Regionen:",
        "ru" : "У нас есть недвижимость в следующих регионах:",
        "fr" : "Nous avons des propriétés dans les régions suivantes:",
        "pl" : "Posiadamy nieruchomości w następujących regionach:"
    },
    "minimum_area" : {
        "en" : "minimum area, m",
        "de" : "Mindestfläche, m",
        "ru" : "мин. площадь, м",
        "fr" : "surface min., m",
        "pl" : "min. powierzch., m"
    },
    "minimum_land_size" : {
        "en" : "min. land, m",
        "de" : "min. Land, m",
        "ru" : "мин. земли, м",
        "fr" : "min. terr., m",
        "pl" : "min. gruntu, m"
    },
    "furnished" : {
        "en" : "furnished",
        "de" : "möbliert",
        "ru" : "мебель",
        "fr" : "meublée",
        "pl" : "umeblowany"
    },
    "sea_view" : {
        "en" : "sea view",
        "de" : "Seeblick",
        "ru" : "вид на море",
        "fr" : "vue sur la mer",
        "pl" : "Widok morza"
    },
    "has_sea_view" : {
        "en" : "has sea view",
        "de" : "hat Meerblick",
        "ru" : "имеет вид на море",
        "fr" : "a vue sur la mer",
        "pl" : "ma widok na morze"
    },
    "list_property" : {
        "en" : "List your property",
        "de" : "Listen Sie Ihre Immobilie auf",
        "ru" : "Зарегистрируйте вашу собственность",
        "fr" : "Inscrivez votre propriété",
        "pl" : "N/A"
    },
    "list_property_url" : {
        "en" : "home",
        "de" : "startseite",
        "ru" : "glavnaya",
        "fr" : "page-d-accueil",
        "pl" : "N/A"
    },
    "back" : {
        "en" : "Back",
        "de" : "Zurück",
        "ru" : "Назад",
        "fr" : "Retour",
        "pl" : "Plecy"
    },
    "logout" : {
        "en" : "Logout",
        "de" : "Ausloggen",
        "ru" : "Выйти",
        "fr" : "Se déconnecter",
        "pl" : "Wyloguj"
    },
    "remove" : {
        "en" : "remove",
        "de" : "Löschen",
        "ru" : "удалять",
        "fr" : "retirer",
        "pl" : "usunąć"
    },
    "login" : {
        "en" : "Log in",
        "de" : "Einloggen",
        "ru" : "Вход",
        "fr" : "Connexion",
        "pl" : "Zaloguj sie"
    },
    "privacy_policy": {
        "en" : "Privacy Policy",
        "de" : "Datenschutz-Bestimmungen",
        "ru" : "Политика Конфиденциальности",
        "fr" : "Politique de Confidentialité",
        "pl" : "Polityka Prywatności"
    },
    "saved" : {
        "en" : "saved",
        "de" : "gespeichert",
        "ru" : "сохранен",
        "fr" : "enregistré",
        "pl" : "zapisane"
    },    
    "not_saved_alerts" : {
        "en" : "save search to receive alerts",
        "de" : "speichern Sie die Suche, um Benachrichti- gungen zu erhalten",
        "ru" : "сохраните поиск, чтобы получать оповещения",
        "fr" : "enregistrer la recherche pour recevoir des alertes",
        "pl" : "zapisz wyszukiwanie, aby otrzymywać powiadomienia"
    },
    "not_saved" : {
        "en" : "not saved",
        "de" : "nicht gespeichert",
        "ru" : "не сохранен",
        "fr" : "pas enregistré",
        "pl" : "nie zapisane"
    },
    "please_login" : {
        "en" : "Please login to view saved properties.",
        "de" : "Bitte melden Sie sich an, um gespeicherte Immobilien anzuzeigen.",
        "ru" : "Пожалуйста, войдите, чтобы просмотреть сохраненные объекты недвижимости.",
        "fr" : "Veuillez vous connecter pour voir les biens immobiliers sauvegardés.",
        "pl" : "Zaloguj się, aby zobaczyć zapisane nieruchomości."
    },
    "username" : {
        "en" : "Username",
        "de" : "Nutzername",
        "ru" : "Имя пользователя",
        "fr" : "Nom d'utilisateur",
        "pl" : "Nazwa Użytkownika"
    },
    "welcome_message" : {
        "en" : "Hello, %username%. You can find your stored properties and searches in the lists below.",
        "de" : "Hallo, %username%. Sie finden Ihre gespeicherten Eigenschaften und Suchanfragen in den folgenden Listen.",
        "ru" : "Здравствуйте, %username%. Вы можете найти свои сохраненные свойства и результаты поиска в списках ниже.",
        "fr" : "Bonjour, %username%. Vous pouvez trouver vos propriétés stockées et vos recherches dans les listes ci-dessous.",
        "pl" : "Witaj, %username%. Możesz znaleźć swoje zapisane właściwości i wyszukiwania na poniższych listach."
    },
    "limit_reached" : {
        "en" : "Limit reached!",
        "de" : "Limit erreicht!",
        "ru" : "Достигнут предел!",
        "fr" : "Limite atteinte!",
        "pl" : "Limit osiągnięty!"
    },

    "please_login_search" : {
        "en" : "Please login to view saved searches.",
        "de" : "Bitte melden Sie sich an, um gespeicherte Suchanfragen anzuzeigen.",
        "ru" : "Пожалуйста, войдите, чтобы просмотреть сохраненные поиски.",
        "fr" : "Veuillez vous connecter pour voir les recherches sauvegardées.",
        "pl" : "Zaloguj się, aby wyświetlić zapisane wyszukiwania."
    },

    "view_desktop_version" : {
        "en" : "view desktop version",
        "de" : "Desktop-Version anzeigen",
        "ru" : "посмотреть настольную версию",
        "fr" : "voir la version de bureau",
        "pl" : "zobacz wersję na komputer"
    },
    "saved_properties" : {
        "en" : "Saved Properties",
        "de" : "Gespeicherte Immobilien",
        "ru" : "Сохраненные недвижимости",
        "fr" : "Biens immobiliers sauvegardés",
        "pl" : "Zapisane nieruchomości"
    },
    "saved_search" : {
        "en" : "Saved Searches",
        "de" : "Gespeicherte Suche",
        "ru" : "Сохраненные поиски",
        "fr" : "Recherches sauvegardées",
        "pl" : "Zapisane wyszukiwania"
    },
    "domains" : {
        "en" : "https://www.cheap-bulgarian-house.co.uk",
        "de" : "https://www.immobilien-bulgarien.com",
        "ru" : "http://doma-bolgaria.com",
        "fr" : "http://www.bulgarieimmobilier.com",
        "pl" : "http://www.nieruchomoscibulgaria.com"
    },
    "bulgaria" : {
        "en" : "Bulgaria",
        "de" : "Bulgarien",
        "ru" : "Bolgaria",
        "fr" : "Bulgarie",
        "pl" : "Bulgaria"
    },
    "share" : {
        "en" : "Share",
        "de" : "Teile",
        "ru" : "Поделись",
        "fr" : "Partager",
        "pl" : "Udostępnij"
    },
    "url_property" : {
        "en" : "property",
        "de" : "immobilie",
        "ru" : "nedvizhimost",
        "fr" : "immobilie",
        "pl" : "nieruchomosc"
    },
    "url_properties": {
        "en" : "properties",
        "ru" : "nedvizhimosti",
        "de" : "immobilien",
        "fr" : "immobilies",
        "pl" : "nieruchomosci"
    },
    "url_home" : {
        "en" : "home",
        "de" : "startseite",
        "ru" : "glavnaya",
        "pl" : "strona-startowa",
        "fr" : "page-d-accueil"
    },
    "url_saved_properties" : {
        "en" : "saved-properties",
        "de" : "gespeicherte-immobilien",
        "ru" : "sohranennie-nedvizhimosti",
        "fr" : "biens-immobiliers-sauvegardes",
        "pl" : "zapisane-nieruchomosci"
    },
    "url_saved_search" : {
        "en" : "saved-searches",
        "de" : "gespeicherte-suche",
        "ru" : "sohranennie-poiski",
        "fr" : "recherches-sauvegardees",
        "pl" : "zapisane-wyszukiwania"
    },
    "user_page" : {
        "en" : "Personal Page",
        "de" : "persönliche Seite",
        "ru" : "личная страница",
        "fr" : "page personnelle",
        "pl" : "strona osobista"
    },
    "user_page_url" : {
        "en" : "user-page",
        "de" : "benutzerseite",
        "ru" : "stranitsa-polzovatelya",
        "fr" : "page-utilisateur",
        "pl" : "strona-uzytkownika"
    },
    "call_us":{
        "en" : "call",
        "ru" : "позвоните нам",
        "de" : "anrufen",
        "fr" : "appelez nous",
        "pl" : "zadzwoń do nas"
    },
    "photos":{
        "en" : "Photos",
        "ru" : "Фотографии",
        "de" : "Fotos",
        "fr" : "Des photos",
        "pl" : "Zdjęcia"
    },
    "video":{
        "en" : "Video",
        "ru" : "Видео",
        "de" : "Video",
        "fr" : "Vidéo",
        "pl" : "Wideo"
    },
    "view_360":{
        "en" : "360 view",
        "ru" : "Видео 360",
        "de" : "Video 360",
        "fr" : "Vidéo 360",
        "pl" : "Wideo 360"
    },
    "view_360_loading":{
        "en" : "360 view video is loaded in a browser tab",
        "ru" : "Видео 360 загружается во вкладке браузера",
        "de" : "Video 360 wird in eine Browser-Registerkarte geladen",
        "fr" : "la vidéo 360 est chargée dans un onglet du navigateur",
        "pl" : "wideo 360 jest ładowane w zakładce przeglądarki"
    },
    "drone_video":{
        "en" : "Drone Video",
        "ru" : "Дрон Видео",
        "de" : "Drohnen-Video",
        "fr" : "Drone Vidéo",
        "pl" : "Wideo Drone"
    },
    "upto_5000":{
        "en" : "under £5000",
        "ru" : "до €5000",
        "de" : "bis €5000",
        "fr" : "dessous de €5000",
        "pl" : "do €5000"
    },
    "home":[{
        "en" : "new entries",
        "ru" : "новые записи",
        "de" : "neue Einträge",
        "fr" : "nouvelles entrées",
        "pl" : "nowe wpisy"
    },{
        "en" : "entries under £5000",
        "ru" : "заявок до €5000",
        "de" : "Einträge unter €5000",
        "fr" : "entrées de moins de €5000",
        "pl" : "wpisów poniżej €5000"
    },{
        "en" : "updated apartments",
        "ru" : "обновленные квартиры",
        "de" : "aktualisierte Wohnungen",
        "fr" : "appartements mis à jour",
        "pl" : "zaktualizowane mieszkania"
    },{
        "en" : "Saved Properties",
        "de" : "Gespeicherte Immobilien",
        "ru" : "Сохраненные недвижимости",
        "fr" : "Biens immobiliers sauvegardés",
        "pl" : "Zapisane nieruchomości"
    },{
        "en" : "Trending",
        "de" : "Trendaufzeichnung",
        "ru" : "Трендовом",
        "fr" : "Immo de Trends",
        "pl" : "Dziennik trendów"
    }],
    "phone_number":{
        "en" : "tel:00447775712704",
        "ru" : "tel:00359879036885",
        "de" : "tel:00359879036885",
        "fr" : "tel:0033185149501",
        "pl" : "tel:00359894086810"
    },
    "email_error":{
        "en" : "There was an error while sending your request. Please, try again later.",
        "ru" : "Произошла ошибка при отправке вашего запроса. Пожалуйста, попробуйте позже.",
        "de" : "Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
        "fr" : "Une erreur s'est produite lors de l'envoi de votre demande. Veuillez réessayer plus tard.",
        "pl" : "Wystąpił błąd podczas wysyłania żądania. Spróbuj ponownie później."
    },
    "thank_you":{
        "en" : "Thank you!",
        "ru" : "Спасибо!",
        "de" : "Vielen Dank!",
        "fr" : "Merci",
        "pl" : "Dzięki"
    },
    "email_is_required":{
        "en" : "Email is required.",
        "ru" : "Требуется электронная почта.",
        "de" : "E-Mail ist erforderlich.",
        "fr" : "Email est requis.",
        "pl" : "Email jest wymagany."
    },    
    "invalid_email_address":{
        "en" : "Invalid email address",
        "ru" : "Неверный адрес электронной почты",
        "de" : "Ungültige E-Mail-Adresse",
        "fr" : "Adresse e-mail invalide",
        "pl" : "Niepoprawny adres email"
    },
    "contactus":{
        "en" : "Contact us",
        "ru" : "Связь с нами",
        "de" : "Kontakt",
        "fr" : "Contactez-nous",
        "pl" : "Kontakt"
    },
    "name": {
        "en" : "Name",
        "ru" : "Имя",
        "de" : "Name",
        "fr" : "Nom",
        "pl" : "Imię"
    },
    "email": {
        "en" : "E-mail",
        "ru" : "Электронная почта",
        "de" : "E-mail",
        "fr" : "E-mail",
        "pl" : "E-mail"
    },
    "phone": {
        "en" : "Phone",
        "ru" : "Телефон",
        "de" : "Telefon",
        "fr" : "Phone",
        "pl" : "Telefon"
    },
    "question": {
        "en" : "Question",
        "ru" : "Вопрос",
        "de" : "Mitteilung",
        "fr" : "Question",
        "pl" : "Pytanie"
    },
    "properties": {
        "en" : "properties",
        "ru" : "недвижимость",
        "de" : "Immobilien",
        "fr" : "propriétés",
        "pl" : "nieruchomości"
    },
    "updated": {
        "en" : "updated",
        "ru" : "обновленный",
        "de" : "aktualisiert",
        "fr" : "mis à jour",
        "pl" : "zaktualizowane"
    },
    "head1" : {
        "en" : "Properties for sale in bulgaria",
        "ru" : "Недвижимость на продажу в болгарии",
        "de" : "Immobilien zum verkauf in bulgarien",
        "fr" : "Propriétés à vendre en bulgarie",
        "pl" : "Nieruchomości na sprzedaż w bułgarii"
    },
    "head2" : {
        "en" : "Properties in ",
        "ru" : "Недвижимость в ",
        "de" : "Immobilien in ",
        "fr" : "Propriétés en ",
        "pl" : "Nieruchomości w "
    },
    "head3" : {
        "en" : "Properties in Bulgaria",
        "ru" : "Недвижимости в Болгарии",
        "de" : "Immobilien in Bulgarien",
        "fr" : "Propriétés en Bulgarie",
        "pl" : "Nieruchomości w Bułgarii"
    },
    "home_nav" : {
        "en" : "Home",
        "de" : "Startseite",
        "ru" : "Главная",
        "pl" : "Strona startowa",
        "fr" : "Page d'accueil"
    },
    "main_search" : {
        "en" : "Main search",
        "de" : "Hauptsuche",
        "ru" : "Главный поиск",
        "pl" : "Główne wyszukiwanie",
        "fr" : "Rechercher"
    },
    "search_by_distance" : {
        "en" : "Search by distance",
        "de" : "Suche nach Entfernung",
        "ru" : "Поиск по расстоянию",
        "pl" : "Szukaj według odległości",
        "fr" : "Recherche par distance"
    },
    "radius" : {
        "en" : "Radius (km)",
        "de" : "Radius (km)",
        "ru" : "Радиус (км)",
        "pl" : "Rayon (km)",
        "fr" : "Rayon (km)"
    },
    "from_city" : {
        "en" : "From city",
        "de" : "Von der Stadt entfernt",
        "ru" : "От города",
        "pl" : "Od miasta",
        "fr" : "De la ville"
    },
    "place" : {
        "en" : "place",
        "de" : "Ort",
        "ru" : "деревня",
        "pl" : "wieś",
        "fr" : "village"
    },
    "region" : {
        "en" : "region",
        "de" : "Region",
        "ru" : "область",
        "pl" : "region",
        "fr" : "région"
    },
    "search_province_village" : {
        "en" : "Search by province / village",
        "de" : "Suche in Bezirke und Dörfer",
        "ru" : "Искать по область/деревня",
        "pl" : "Szukaj po województw / miejscowości",
        "fr" : "Annonces par région"
    },
    "ref_id" : {
        "en" : "Property ID",
        "de" : "Haus Nr.",
        "ru" : "Недвижимост ID",
        "fr" : "Maison Nr.",
        "pl" : "Nieruchomość ID"
    },
    "search" : {
        "en" : "Search",
        "de" : "Suche",
        "ru" : "Поиск",
        "fr" : "Chercher",
        "pl" : "Szukaj"
    },
    "reset" : {
        "en" : "Reset",
        "de" : "Zurücksetzen",
        "ru" : "Сброс",
        "fr" : "Réinitialiser",
        "pl" : "Nastawić"
    },
    "money_sign" : {
        "en" : "£",
        "de" : "€",
        "ru" : "€",
        "fr" : "€",
        "pl" : "€"
    },
    "price" : {
        "en" : "Price",
        "de" : "Preis",
        "ru" : "Цена",
        "fr" : "Prix",
        "pl" : "Cena"
    },
    "theme" : {
        "en" : "theme",
        "de" : "Thema",
        "ru" : "тема",
        "fr" : "thème",
        "pl" : "motyw"
    },
    "no_property_found" : {
        "en" : "There is no such property ID or this property was sold.",
        "ru" : "Нет идентификатора такой собственности или эта недвижимость была продана.",
        "de" : "Es gibt keine solche Immobilien ID oder diese Immobilien wurde verkauft.",
        "fr" : "Cet identifiant de propriété n'existe pas ou cette propriété a été vendue.",
        "pl" : "Nie ma takiego identyfikatora nieruchomości lub ta nieruchomość została sprzedana."
    },
    "no_properties_found" : {
        "en" : "No properties found.",
        "ru" : "Недвижимость не найдена",
        "de" : "Keine Immobilien gefunden",
        "fr" : "Aucun bien immobilier trouvé",
        "pl" : "Nie znaleziono nieruchomości"
    },
    "no_search_criteria" : {
        "en" : "No search criteria",
        "de" : "Keine Suchkriterien",
        "ru" : "Нет критериев поиска",
        "fr" : "Aucun critère de recherche",
        "pl" : "Brak kryteriów wyszukiwania"
    },
    "search_criteria" : {
        "en" : "Search criteria",  
        "ru" : "Критерий поиска",  
        "de" : "Suchkriterium",  
        "fr" : "Critères de recherche",  
        "pl" : "Kryteria wyszukiwania",  
    },
    "province" : {
        "en" : "Province",
        "de" : "Bezirk",
        "ru" : "Провинция",
        "fr" : "Province",
        "pl" : "Województwo"
    },
    "city_village" : {
        "en" : "City / Village",
        "ru" : "Город / Деревня",
        "de" : "Stadt / Dorf",
        "fr" : "Ville / Village",
        "pl" : "Miasto / Wieś"
    },
    "please_select" : {
        "en" : "Please select",
        "de" : "Bitte auswählen",
        "ru" : "Пожалуйста выберите",
        "fr" : "Veuillez choisir",
        "pl" : "Proszę wybrać",
    },
    "sqm" : {
        "en" : "sqm",
        "de" : "m2",
        "ru" : "м2",
        "fr" : "m2",
        "pl" : "m2",
    },
    "km" : {
        "en" : "km",
        "de" : "km",
        "ru" : "км",
        "fr" : "km",
        "pl" : "km",
    },
    "meters_to_sea" : {
        "en" : "km to sea",
        "de" : "km zum Meer",
        "ru" : "км до моря",
        "fr" : "km de la mer",
        "pl" : "km do morza"
    },
    /*
    "meters_to_sea" : {
        "en" : "meters to sea",
        "de" : "Meter zum Meer",
        "ru" : "метров до моря",
        "fr" : "mètres de la mer",
        "pl" : "metrów do morza"
    },
    */
    "sort_by_select" : {
        "en" : {
            label: "Sort by",
            options: {
                "3" : "Updated first",
                "0": "Price (low to high)",
                "1" : "Price (high to low)",
                "2" : "km. to sea",
                "4" : "Land size (high to low)"
            }
        },
        "de" : {
            label: "Sortieren nach",
            options: {
                "3" : "Aktualisiert",
                "0": "Preise (min bis max)",
                "1" : "Preise (max bis min)",
                "2" : "km vom Meer",
                "4" : "Grund (max bis min)"
            }
        },
        "ru" : {
            label: "Сортировать по",
            options: {
                "3" : "Обновленный",
                "0": "Цена (возрастание)",
                "1" : "Цена (убывание)",
                "2" : "До моря",
                "4" : "Земля (убывание)"
            }
        },
        "fr" : {
            label: "Trier par",
            options: {
                "3" : "Mise à jour",
                "0": "Prix (min. au max.)",
                "1" : "Prix (max. au min.)",
                "2" : "km. à la mer",
                "4" : "Parcelle (max. au min.)"
            }
        },
        "pl" : {
            label: "Sortuj",
            options: {
                "3" : "Aktualizacja",
                "0": "Cena (min do max)",
                "1" : "Cena (max do min)",
                "2" : "Odległ. od morza",
                "4" : "Ziemia (max do min)"
            }
        },
    },
    "date_added_select" : {
        "en" : {
            label: "Added to site",
            options: {
                "0" : "any time",
                "24" : "last 24 hours",
                "72" : "last 3 days",
                "168" : "last 7 days",
                "240" : "last 10 days",
                "720" : "last 30 days",
                "2160" : "last 90 days",
                "4320" : "last 180 days",
            }
        },
        "ru" : {
            label: "В продаже с",
            options: {
                "0" : "любое время",
                "24" : "24 часа",
                "72" : "3 дня",
                "168" : "7 дней",
                "240" : "10 дней",
                "720" : "1 месяц",
                "2160" : "3 месяца",
                "4320" : "6 месяца",
            }
        },
        "de" : {
            label: "wenn hinzugefügt",
            options: {
                "0" : "alle",
                "24" : "letzten 24 Stunden",
                "72" : "letzten 3 Tage",
                "168" : "letzten 7 Tage",
                "240" : "letzten 10 Tage",
                "720" : "letzten 30 Tage",
                "2160" : "letzten 3 Monate",
                "4320" : "letzten 6 Monate",
            }
        },
        "fr" : {
            label: "Ajouté au site",
            options: {
                "0" : "tous les temps",
                "24" : "24 dernières heures",
                "72" : "3 derniers jours",
                "168" : "7 derniers jours",
                "240" : "10 derniers jours",
                "720" : "30 derniers jours",
                "2160" : "90 derniers jours",
                "4320" : "180 derniers jours",
            }
        },
        "pl" : {
            label: "Dodano na stronę",
            options: {
                "0" : "za każdym razem",
                "24" : "ostatnie 24 godziny",
                "72" : "z ostatnich 3 dni",
                "168" : "z ostatnich 7 dni",
                "240" : "z ostatnich 10 dni",
                "720" : "z ostatnich 30 dni",
                "2160" : "ostatnie 3 miesiące",
                "4320" : "ostatnie 6 miesiące",
            }
        }
    },
    "land": {
        "en" : "land",
        "de" : "Land",
        "ru" : "земля",
        "fr" : "terre",
        "pl" : "wylądować",
    },
    "plocation_select" : {
        "en" : {
            label : "Location",
            options: {
                "0" : "All Locations",
                "1" : "Black Sea - Varna", 
                "2" : "Black Sea - Bourgas", 
                "3" : "In Ski Resorts", 
                "4" : "Sofia City and Region", 
                "5" : "Mountains", 
                "6" : "Golf", 
                "7" : "Bulgaria - Urban", 
                "8" : "Bulgaria - Rural", 
            }
        },
        "ru" : {
            label : "Месторасположение",
            options: {
                "0" : "Все местоположения", 
                "1" : "Северное поб. моря, Варна", 
                "2" : "Южное поб. моря, Бургас", 
                "3" : "Лыжные курорты", 
                "4" : "София и область", 
                "5" : "Горные районы", 
                "6" : "Гольф", 
                "7" : "В городах", 
                "8" : "Недалеко от города", 
            }
        },
        "de" : {
            label : "Gebiet",
            options: {
                "0" : "alle", 
                "1" : "nähe Meer, Norden, Varna", 
                "2" : "nähe Meer, Süden, Burgas", 
                "3" : "in Skigebieten", 
                "4" : "nahe bei Sofia", 
                "5" : "Berghang", 
                "6" : "Golf", 
                "7" : "in den Städten", 
                "8" : "in den Dörfern", 
            }
        },
        "fr" : {
            label : "Localisation",
            options: {
                "0" : "tous", 
                "1" : "mer Noire - Varna", 
                "2" : "mer Noire - Bourgas", 
                "3" : "les stations de ski", 
                "4" : "Sofia ville et la Région", 
                "5" : "Montagnes", 
                "6" : "Golf", 
                "7" : "Bulgarie - Urban", 
                "8" : "Bulgarie - Rural", 
            }
        },
        "pl" : {
            label : "Lokalizacje",
            options: {
                "0" : "Wszystkie lokalizacje", 
                "1" : "Północne Wybrz. Morza, Warna", 
                "2" : "Południowe Wybr. Morza,Burgas", 
                "3" : "Ośrodki narciarskie", 
                "4" : "Sofia i region", 
                "5" : "Obszary górskie", 
                "6" : "Golf", 
                "7" : "Miasta", 
                "8" : "Niedaleko od miasta", 
            }
        },
    },
    "ptype_select" : {
        "en" : {
            label : "Type",
            options: {
                "0" : "All Types", 
                "10" : "1 bedroom", 
                "11" : "2 bedrooms", 
                "12" : "3 bedrooms", 
                "13" : "3+ bedrooms", 
                "14" : "commerce", 
                "15" : "Apartments", 
                "16" : "Land - for building", 
                "17" : "Land - agrucultural", 
            }
        },
        "ru" : {
            label : "Тип недвижимости",
            options: {
                "0" : "Все типы", 
                "10" : "дом 2 комнатный", 
                "11" : "дом 3 комнатный", 
                "12" : "дом 4 комнатный", 
                "13" : "дом 4+ комнатный", 
                "14" : "комерческая недвиж.", 
                "15" : "апартаменты", 
                "16" : "участок под застройку", 
                "17" : "земли сельскох", 
            }
        },
        "de" : {
            label : "Schlafzimmer",
            options: {
                "0" : "alle", 
                "10" : "1", 
                "11" : "2", 
                "12" : "3", 
                "13" : "mehr als 3", 
                "14" : "kommerziellen", 
                "15" : "Apartments", 
                "16" : "Baugrundstück", 
                "17" : "Ackerland", 
            }
        },
        "fr" : {
            label : "chambres à coucher",
            options: {
                "0" : "tous", 
                "10" : "1 chambre", 
                "11" : "2 chambres", 
                "12" : "3 chambres", 
                "13" : "3 + chambres", 
                "14" : "commerce", 
                "15" : "appartments", 
                "16" : "terrain constructible", 
                "17" : "les terres agricoles", 
            }
        },
        "pl" : {
            label : "Rodzaj nieruchomości",
            options: {
                "0" : "Wszystkie typy",
                "10" : "dom z 1 pokojem", 
                "11" : "dom z 2 pokojami", 
                "12" : "dom z 3 pokojami", 
                "13" : "dom z 3+ pokojami", 
                "14" : "nieruchomość  komerc.", 
                "15" : "mieszkanie", 
                "16" : "działka", 
                "17" : "użytki rolne", 
            }
        }
    },
}